import "@/styles/globals.scss";
// import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { MultiThemeProvider } from "@/utils/multi-theme-provider";
import { AnalyticsProvider } from "@/utils/analytics-provider";
import NextSeoWrapper from "@/utils/next-seo-wrapper";
import Layout from "@/components/layout";
import { SnackbarProvider } from "notistack";
import { appWithTranslation } from "next-i18next";
import { InquiryContextProvider } from "@/utils/inquiry-context";
import { LazyMotion, domAnimation } from "framer-motion";

const MyApp = ({ Component, pageProps }) => {
  // This is a hack to fix the issue with the hash links not working properly, covered by navbar.
  // useEffect(() => {
  //   if (location.hash) {
  //     const timeout = setTimeout(() => scrollBy(0, -150), 0);
  //   }

  //   var shiftWindow = function () {
  //     scrollBy(0, -150);
  //   };
  //   window.addEventListener("hashchange", shiftWindow);

  //   return () => {
  //     try {
  //       clearTimeout(timeout);
  //     } catch (e) {}
  //     window.removeEventListener("hashchange", shiftWindow);
  //   };
  // }, []);

  return (
    <CookiesProvider>
      <LazyMotion features={domAnimation} strict>
        <MultiThemeProvider>
          <AnalyticsProvider>
            <InquiryContextProvider>
              <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
                <NextSeoWrapper />
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </SnackbarProvider>
            </InquiryContextProvider>
          </AnalyticsProvider>
        </MultiThemeProvider>
      </LazyMotion>
    </CookiesProvider>
  );
};

export default appWithTranslation(MyApp);
