import Link from "next/link";
import React from "react";
import { useTranslation } from "next-i18next";

const RequestQuoteButton = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Link className={`button ${className}`} href="/inquiry">
      {t("request-quote")}
    </Link>
  );
};

export default RequestQuoteButton;
