// Next-SEO Configuration. Used by next-seo package.

const config = {
  titleTemplate: "%s | AlHayat Foodstuff",
  // facebook: { appId: "alhayatfoodstuff" },
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://alhayatfoodstuff.com/",
    siteName: "AlHayat Foodstuff",
    urlTemplate: "https://alhayatfoodstuff.com/%s",
  },
  // twitter: {
  //   handle: "@alhayatfoodstuff",
  //   site: "@alhayatfoodstuff",
  //   cardType: "summary_large_image",
  // },
};

const socialProfile = {
  type: "Organization",
  name: "AlHayat Foodstuff",
  url: "https://alhayatfoodstuff.com/",
  sameAs: [
    "https://www.facebook.com/alhayatfoodstuff",
    // "https://www.instagram.com/eloquence.languagelearning/",
    // "https://www.tiktok.com/@eloquence.lang.learning?_t=8eSZnzpTRPW&_r=1",
    "https://www.linkedin.com/company/alhayatfoodstuff/",
  ],
};

export { config, socialProfile };
