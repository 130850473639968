import React from "react";
import themes from "@/styles/themes";
import { useLocalStorage } from "usehooks-ts";
import Tooltip from "@/components/common/tooltip";
import Head from "next/head";
import { useMediaQuery } from "usehooks-ts";
import { RadioGroup } from "@headlessui/react";

const ThemeContext = React.createContext();

export const MultiThemeProvider = ({ initialTheme = "auto", children }) => {
  const [theme, setTheme] = React.useState("light-theme");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersHighContrast = useMediaQuery("(prefers-contrast: more)");
  const [themePreference, setThemePreference] = useLocalStorage(
    "themePreference",
    initialTheme,
  );
  const [systemPreference, setSystemPreference] = React.useState("light-theme");

  // Monitor system preference
  React.useEffect(() => {
    if (prefersDarkMode) {
      // console.log("Prefers Dark Mode");
      setSystemPreference("dark-theme");
    } else if (prefersHighContrast) {
      // console.log("Prefers High Contrast");
      setSystemPreference("high-contrast-theme");
    } else {
      // console.log("Prefers Light Mode");
      setSystemPreference("light-theme");
    }
  }, [prefersDarkMode, prefersHighContrast]);

  // Monitor theme preference and system preference and update theme accordingly
  React.useEffect(() => {
    if (themePreference === "auto") {
      // console.log("Setting Theme: " + systemPreference);
      setTheme(systemPreference);
    } else {
      // console.log("Setting Theme: " + themePreference);
      setTheme(themePreference);
    }
    document.getElementsByTagName("html")[0].className = theme;
  }, [themePreference, systemPreference, theme]);

  const data = {
    themePreference: themePreference,
    setThemePreference: setThemePreference,
  };

  return (
    <ThemeContext.Provider value={data}>
      <Head>
        <meta
          name="theme-color"
          content={theme === "dark-theme" ? "#262626" : "#ffffff"}
        />
      </Head>
      {children}
    </ThemeContext.Provider>
  );
};

const ThemeSelector = () => {
  const { themePreference, setThemePreference } =
    React.useContext(ThemeContext);

  const buttonData = Object.keys(themes).map((key) => {
    return {
      value: key,
      name: themes[key].name,
      icon: themes[key].icon,
    };
  });

  return (
    <RadioGroup
      value={themePreference}
      onChange={setThemePreference}
      aria-label="Select Theme"
      className="flex justify-center overflow-hidden rounded-md border border-auto-black/30"
    >
      <RadioGroup.Label className="sr-only">Select Theme</RadioGroup.Label>
      {buttonData.map((button, i) => (
        <>
          <Tooltip key={button.value} description={button.name}>
            <RadioGroup.Option
              value={button.value}
              className="flex h-12 w-12 items-center justify-center text-auto-black/50 hover:bg-primary/20 ui-checked:bg-secondary-500/20 ui-checked:text-secondary-500/80"
            >
              <button.icon className="pointer-events-none h-6 w-6" />
            </RadioGroup.Option>
          </Tooltip>
          {i < buttonData.length - 1 && (
            <div className="h-12 w-px bg-auto-black/30" />
          )}
        </>
      ))}
    </RadioGroup>
  );
};

export default ThemeSelector;
