// export type NavLink = {
//   // icon: React.ReactNode;
//   label: string;
//   href: string;
//   showInHeaderFrom: "sm" | "md" | "lg" | "xl" | "2xl" | "never";
//   minWidth: 600 | 900 | 1200 | 1500 | 1800 | 2100 | 2400 | 2700 | 3000;
// };

const NavLinks = [
  {
    // icon: <HomeIcon />,
    label: "nav:home",
    href: "/",
    showInHeaderFrom: "md",
    minWidth: 900,
  },
  {
    // icon: <CodeIcon />,
    label: "nav:products",
    href: "/products",
    showInHeaderFrom: "md",
    minWidth: 900,
  },
  {
    // icon: <DocumentTextIcon />,
    label: "nav:about",
    href: "/about",
    showInHeaderFrom: "md",
    minWidth: 900,
  },
  {
    // icon: <DocumentTextIcon />,
    label: "nav:contact",
    href: "/contact",
    showInHeaderFrom: "md",
    minWidth: 900,
  },
  // {
  //   // icon: <BookOpenIcon />,
  //   label: "Blog",
  //   href: "/blog",
  //   showInHeaderFrom: "md",
  //   minWidth: 900,
  // },
];

export default NavLinks;
