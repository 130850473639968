import React from "react";
import AnalyticsContext from ".";

const ManageConsentButton = ({
  toggleActive = () => {},
  asLink = false,
  className = "",
}) => {
  const { setConsentDialogOpen } = React.useContext(AnalyticsContext);
  return (
    <>
      {asLink ? (
        <a
          href="#"
          className={className}
          onClick={(e) => {
            e.preventDefault();
            setConsentDialogOpen(true);
            toggleActive();
          }}
        >
          Cookie Preferences
        </a>
      ) : (
        <button
          className={`button border-secondary ${className}`}
          onClick={() => {
            setConsentDialogOpen(true);
            toggleActive();
          }}
        >
          Cookie Preferences
        </button>
      )}
    </>
  );
};

export default ManageConsentButton;
