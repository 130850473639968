import { Lato as HeaderFont, Lato as BodyFont } from "next/font/google";

import React from "react";
import { useRouter } from "next/router";
import AppBar from "./appbar";
import Footer from "./footer";
import { m, AnimatePresence } from "framer-motion";

const headerFont = HeaderFont({
  subsets: ["latin"],
  //weight: //variable font; doesn't require weight
  weight: ["100", "300", "400", "700", "900"],
  display: "swap",
  variable: "--font-header",
});

const bodyFont = BodyFont({
  subsets: ["latin"],
  weight: ["100", "300", "400", "700", "900"],
  display: "swap",
  variable: "--font-body",
});

const Layout = ({ defaultSidebarVisible = false, children }) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(
    defaultSidebarVisible,
  );

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const motionVariants = {
    main: {
      hidden: {
        opacity: 0,
        // x: -50,
        transition: {
          duration: 0.2,
        },
      },
      visible: {
        opacity: 1,
        // x: 0,
        transition: {
          duration: 0.2,
        },
      },
    },
  };

  return (
    <div
      className={`flex min-h-screen flex-col ${
        defaultSidebarVisible ? " default-sidebar-open" : ""
      }${sidebarVisible ? " sidebar-visible" : ""} ${headerFont.variable} ${
        bodyFont.variable
      } high-contrast-theme:font-medium high-contrast-theme:text-black`}
    >
      <AppBar toggleSidebar={toggleSidebar} />
      <main className="page-content font-body text-auto-black/80">
        <AnimatePresence mode="wait" initial={false}>
          <m.main
            key={useRouter().pathname}
            variants={motionVariants.main}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ type: "inertia" }}
          >
            <AnimatePresence initial>{children}</AnimatePresence>
          </m.main>
        </AnimatePresence>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
