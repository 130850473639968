import React from "react";
import { Menu, Transition } from "@headlessui/react";
import NavLinks from "./nav-links";
import Link from "next/link";
import { useRouter } from "next/router";
import ThemeSelector from "@/utils/multi-theme-provider";
// import LanguageSwitcher from "@/utils/i18n/language-switcher";
import { useTranslation } from "next-i18next";
import RequestQuoteButton from "./request-quote-button";

const NavMenu = () => {
  const { t } = useTranslation();
  return (
    <Menu as="nav" className="relative flex items-center gap-2 text-left">
      {({ open, close }) => (
        <>
          {/* This div is just to make tailwind generate the classes used conditaionally below. */}
          {/* <div className="sr-only sm:block sm:hidden md:block md:hidden lg:block lg:hidden xl:block xl:hidden" /> */}
          {NavLinks.map((link) => (
            // const current = link.href === router.pathname;
            // const showLink = link.showInHeaderFrom === "sm" || current;
            <NavItem
              key={link.label}
              // current={current}
              // showLink={showLink}
              link={link}
            />
          ))}
          <div className="hidden h-10 w-[1px] bg-auto-black/20 md:block" />
          {/* <LanguageSwitcher className="hidden sm:flex" /> */}
          <RequestQuoteButton className="hidden h-10 px-4 py-1.5 sm:flex" />
          <Menu.Button
            className="flex h-10 w-10 items-center justify-center rounded hover:bg-primary/20 focus:outline-none"
            open={open}
          >
            <span className="sr-only">Open Menu</span>
            <div className="flex h-6 w-6 flex-col items-center justify-center gap-1">
              <span
                className={`h-1 w-6 origin-center rounded-full bg-auto-black/60 transition-transform duration-100 ease-out will-change-transform ${open ? "-translate-x-2d translate-y-2 -rotate-45" : ""}`}
              />
              <span
                className={`h-1 w-6 origin-center rounded-full bg-auto-black/60 transition-transform duration-100 ease-out will-change-transform ${open ? "scale-x-0" : ""}`}
              />
              <span
                className={`h-1 w-6 origin-center rounded-full bg-auto-black/60 transition-transform duration-100 ease-out will-change-transform ${open ? "-translate-x-2d -translate-y-2 rotate-45" : ""}`}
              />
            </div>
          </Menu.Button>
          <Transition
            className="-ml-2"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute mt-6 flex origin-top-right flex-col gap-1 rounded-md bg-auto-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ltr:right-0 rtl:left-0">
              <Menu.Item
                as="div"
                key="menu-settings"
                className="flex w-full cursor-pointer items-center justify-center gap-2"
              >
                {/* <LanguageSwitcher className="flex sm:hidden" /> */}
                <ThemeSelector />
              </Menu.Item>
              {NavLinks.map((link, i) => (
                <>
                  <Menu.Item
                    as={Link}
                    key={link.label}
                    className={`block w-full cursor-pointer ${link.showInHeaderFrom}:hidden`}
                    href={link.href}
                    onClick={close}
                  >
                    {({ active }) => (
                      <div
                        className={`w-full rounded-md p-2 ${
                          active ? "bg-primary/20 text-primary-900" : ""
                        }`}
                      >
                        {t(link.label)}
                      </div>
                    )}
                  </Menu.Item>
                  {i < NavLinks.length - 1 && (
                    <div
                      key={`divider-${link.label}`}
                      className={`h-px bg-auto-black/10 ${link.showInHeaderFrom}:hidden`}
                    />
                  )}
                </>
              ))}
              <Menu.Item
                as={Link}
                key="menu-inquiry"
                className={`button flex sm:hidden`}
                href="/inquiry"
              >
                {t("request-quote")}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default NavMenu;

// interface NavItemProps extends React.HTMLProps<HTMLDivElement> {
//   link: NavLink;
// }

function NavItem({ link }) {
  const { t } = useTranslation();
  const router = useRouter();
  const [current, setCurrent] = React.useState(false);
  // const [showLink, setShowLink] = React.useState(false);

  React.useEffect(() => {
    const current =
      link.href === router.pathname ||
      (link.href === "/products" && router.pathname.startsWith("/product"));
    setCurrent(current);
    // const checkShowLink = () => {
    //   setShowLink(
    //     window.innerWidth >= link.minWidth &&
    //       window.screen.width >= link.minWidth,
    //     // ||
    //     // (current && window.innerWidth >= 600 && window.screen.width >= 600),
    //   );
    // };
    // checkShowLink();
    // window.addEventListener("resize", checkShowLink);
    // return () => {
    //   window.removeEventListener("resize", checkShowLink);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <div
      className={`relative ml-0 hidden  h-10 max-w-xs items-center justify-center overflow-hidden rounded px-4 py-1.5 text-lg will-change-auto hover:bg-primary/20 md:block`}
    >
      {/* <Transition
      key={link.label}
      show={showLink}
      appear={true}
      className="relative ml-0 flex max-w-xs items-center justify-center overflow-hidden rounded px-4 py-1.5 text-lg will-change-auto hover:bg-primary/20"
      enter="transition-all duration-100 transition-cos"
      enterFrom="-ml-2 max-w-0 px-0 opacity-0"
      enterTo="ml-0 max-w-xs px-4 opacity-100"
      leave="transition-all delay-200 duration-100 transition-cos"
      leaveFrom="max-w-xs ml-0 px-4 opacity-100"
      leaveTo="-ml-2 max-w-0 px-0 opacity-0"
    >
      <Transition.Child
        appear
        enter="transition-opacity duration-100 transition-cos"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100 transition-cos"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      > */}
      <Link
        className={`-mx-4 px-2 py-1.5 text-auto-black/60 ${current ? "text-primary" : ""}`}
        href={link.href}
      >
        {t(link.label)}
      </Link>
      {/* </Transition.Child> */}
      <Transition
        appear={true}
        show={current}
        className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
        enter="transition-all duration-1000 transition-cos"
        enterFrom="left-1/2 right-1/2"
        enterTo="left-0 right-0"
        entered="transition-all duration-300 transition-cos left-0 right-0"
        leave="transition-all duration-300 transition-cos"
        leaveFrom="left-0 right-0"
        leaveTo="left-1/2 right-1/2"
      />
      {/* </Transition> */}
    </div>
  );
}
