import React from "react";
import { useLocalStorage } from "usehooks-ts";
/*
context for inquiry form
format:
    products: [
        {
            code: string,
            name: string,
            options: {/* product options object * /},
        }
    ]
*/
const InquiryContext = React.createContext();

const InquiryContextProvider = ({ children }) => {
  const [products, setProducts] = useLocalStorage("products", [], {
    initializeWithValue: false,
  });

  const addProduct = (product) => {
    const newProducts = [...products, product];
    setProducts(newProducts);
  };

  const removeProduct = (product) => {
    const newProducts = products.filter((p) => p.code !== product.code);
    setProducts(newProducts);
  };

  const value = {
    products,
    setProducts,
    addProduct,
    removeProduct,
  };

  return (
    <InquiryContext.Provider value={value}>{children}</InquiryContext.Provider>
  );
};

export default InquiryContext;
export { InquiryContextProvider };
