// Import theme icons
import BrightnessAutoIcon from "@heroicons/react/24/solid/SparklesIcon";
import LightModeIcon from "@heroicons/react/24/solid/SunIcon";
import DarkModeIcon from "@heroicons/react/24/solid/MoonIcon";
import ContrastIcon from "@heroicons/react/24/solid/EyeIcon";

const themes = {
  auto: { name: "Auto", icon: BrightnessAutoIcon },
  "light-theme": {
    name: "Light Theme",
    icon: LightModeIcon,
  },
  "dark-theme": { name: "Dark Theme", icon: DarkModeIcon },
  "high-contrast-theme": {
    name: "High Contrast Theme",
    icon: ContrastIcon,
  },
};

export default themes;
