import {
  Provider,
  Portal,
  Root,
  Trigger,
  Content,
  Arrow,
} from "@radix-ui/react-tooltip";

const Tooltip = ({ children, description, arrow = true, side = "bottom" }) => {
  return (
    <Provider delayDuration={100}>
      <Root>
        <Trigger asChild>{children}</Trigger>
        <Portal>
          <Content
            side={side}
            sideOffset={3}
            className="data-[state=delayed-open]:animate-fade-in data-[state=closed]:animate-fade-out z-[2000] rounded bg-auto-black/80 px-2 py-1 text-xs font-medium text-auto-white"
          >
            {description}
            {arrow && <Arrow className="fill-auto-black/80" />}
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
};

export default Tooltip;
