import * as React from "react";
import Image from "next/image";
import * as logo from "@/public/img/logo-side-color.svg";
import NavMenu from "./nav-menu";
import Link from "next/link";

const AppBar = ({ toggleSidebar }) => {
  // const analytics = React.useContext(AnalyticsContext);

  return (
    <header className="sticky top-0 z-app-bar h-16 w-screen bg-app-bar p-3 font-body text-auto-black/80 shadow-md high-contrast-theme:font-medium">
      <div className="container flex items-center justify-between px-4">
        <Link
          className="flex items-center gap-4"
          href="/"
          aria-label="Go to Home"
        >
          <Image src={logo} alt="AlHayat Foodstuff Logo" height={40} />
        </Link>
        <NavMenu />
      </div>
    </header>
  );
};

export default AppBar;
